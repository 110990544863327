<template>
  <div>
    <template v-for="(item, index) in termsConditions. terms_and_condition_heading">
      <v-row :class="$vuetify.breakpoint.xsOnly ? 'apply-terms-height': ''" :key="`terms-${index}`">
        <v-img :height=" $vuetify.breakpoint.xsOnly ? '' : 650" style="object-fit: scale-down !important;" class="img-gradient-overlay-industries" :src="item.image">
        <v-col cols="12" style="height:100%" class="d-flex align-center">
          <v-container  class="apply-index">
            <h1 class="font-weight-bold mb-4 text-center" style="color:#ffffff !important;"
              :class="$vuetify.breakpoint.xlOnly ? 'text-h2': $vuetify.breakpoint.lgOnly ?  'text-h3' : 'text-h6'">
              {{ item.title }}
            </h1>
          </v-container>
        </v-col>
        </v-img>
        <!-- <square-animation></square-animation> -->
      </v-row>
    </template>
    <v-row class="ma-0" >
    <v-container style="background:#ffffff"  :class="$vuetify.breakpoint.lgAndUp ? 'common-width': ''">
      <v-row class="ma-0 pa-xl-12" :class="$vuetify.breakpoint.mdAndDown ? 'apply-column' : ''">
        <v-col xs="12" lg="12" xl="12" md="12" sm="12" v-for="(item, index) in  termsConditions.terms_and_condition_list"
          :key="index"
          >
          <p class="text-left pa-0 font-weight-bold">{{ item.title }}</p>
          <p class="text-left pa-0 " v-html="item.description"></p>
        </v-col>
      </v-row>
    </v-container>
    </v-row>
  </div>
</template>
<script>
import { api } from '../../plugins/axios'
export default {
  data () {
    return {
      termsConditions: {}
    }
  },
  // components: {
  //   'square-animation': () => import('../SquareAnimation/index.vue')
  // },
  mounted () {
    this.geyTermsConditions()
    this.scrollToTop()
  },
  methods: {
    geyTermsConditions () {
      api.get('/terms-and-condition')
        .then((response) => {
          this.termsConditions = response.data
        })
        .catch((error) => {
          console.log(error.message)
          if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
          }
        })
    },
    scrollToTop () {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Adds smooth scrolling behavior
      })
    }
  }
}
</script>

<style>
.gradient-terms {
  background: linear-gradient(to right, #FFA726, #c0c9db);
}
.apply-terms-height {
  height: 50vh !important;
}
</style>
